const util = require('./util')
const booter = require('./booter')

function main () {
  // NOTE-DZH: this belongs on the focus component, but it has never been extracted
  fetchFocusCounts().then(displayFocusCounts).catch(err => {
    console.error('Unable to load focus counts.', err)
    if (err.name === 'TypeError') return // ignore network errors
    window.Sentry && window.Sentry.captureException(err)
  })

  document.querySelector('.search-form').addEventListener('submit', () => {
    window.fathom && window.fathom.trackGoal('NI7ETK0Y')
  })
}

function fetchFocusCounts () {
  return util.getJson('/profile/focus/count').then(json => {
    return json.focuses
  }).catch(err => {
    window.Sentry && window.Sentry.captureException(err)
  })
}

function displayFocusCounts (focuses) {
  const tiles = document.querySelectorAll('.community-priorities .focus-tile')
  Array.from(tiles).forEach(tileElement => {
    const selectedKey = tileElement.getAttribute('data-key')
    const found = focuses.find(focus => focus.key === selectedKey)
    if (found) {
      const countElement = tileElement.querySelector('.focus-tile__count')
      countElement.innerText = found.formattedCount
      countElement.setAttribute('title', countElement.getAttribute('title').replace('0', found.formattedCount))
      countElement.removeAttribute('hidden')
    }
  })
}

booter.boot(main)
