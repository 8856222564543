const util = require('./util')

export function browserSupportsAllFeatures () {
  return window.Promise &&
    window.fetch &&
    'closest' in document.documentElement &&
    'from' in Array
}

export function loadPolyfill (mainFn) {
  util.loadScript(window.jsRoot + '/polyfills.js', mainFn)
}
