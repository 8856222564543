const polyfiller = require('./polyfiller')
const outboundLinks = require('./outboundLink')

export function boot (mainFn) {
  function init () {
    outboundLinks.trackAll()
    mainFn && mainFn()
  }

  if (polyfiller.browserSupportsAllFeatures()) {
    init()
  } else {
    // older browsers loads polyfills before running main
    polyfiller.loadPolyfill(init)
  }
}
