function trackOutboundLinkClick (event) {
  const target = event.currentTarget
  if (!target.href) return
  window.fathom && window.fathom.trackGoal('MWXQAAQF')
}

function init () {
  const externalLinks = document.querySelectorAll('a[href^="http"]')
  Array.from(externalLinks).forEach(link => link.addEventListener('click', trackOutboundLinkClick))
}

module.exports = {
  trackAll: init
}
