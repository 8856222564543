const newHTTPError = function (msg, status) {
  const err = new Error(msg)
  err.status = status
  return err
}

const ajaxFetchOptions = function (options) {
  return Object.assign({
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }, options)
}

exports.loadScript = (src, done) => {
  const script = document.createElement('script')
  script.src = src
  script.onload = function () {
    done && done()
  }
  script.onerror = function () {
    if (done) {
      return done(new Error('Failed to load script ' + src))
    }
    throw new Error('Failed to load script ' + src)
  }

  document.head.appendChild(script)
}

exports.postJson = (url, data, method = 'POST') => {
  return window.fetch(url, ajaxFetchOptions({
    method,
    redirect: 'manual',
    body: JSON.stringify(data)
  })).then(response => {
    if (response.redirected) {
      throw newHTTPError(`Failed to update: Unexpected redirect: ${response.url}`, response.status)
    }
    if (!response.ok) {
      throw newHTTPError('Failed to post json to ' + url, response.status)
    }
    if (response.status === 204) {
      return
    }
    return response.json()
  })
}

exports.getJson = (url) => {
  return window.fetch(url, ajaxFetchOptions()).then(response => {
    if (!response.ok) {
      throw newHTTPError('Failed to fetch json from ' + url, response.status)
    }
    return response.json()
  })
}

exports.postFile = (url, data, method = 'POST') => {
  return window.fetch(url, {
    method,
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json'
    },
    body: data
  }).then(response => {
    if (!response.ok) {
      throw newHTTPError('Failed to post file to ' + url, response.status)
    }
    return response.json()
  })
}

exports.deleteRequest = (url) => {
  return window.fetch(url, ajaxFetchOptions({ method: 'DELETE' })).then(response => {
    if (!response.ok) {
      throw newHTTPError('Failed to delete entity', response.status)
    }
    return response
  })
}

// required for IE8 and IE9
exports.toggleClass = (el, className) => {
  if (el.classList) {
    el.classList.toggle(className)
  } else {
    const classes = el.className.split(' ')

    const existingIndex = classes.indexOf(className)
    if (existingIndex >= 0) {
      classes.splice(existingIndex, 1)
    } else {
      classes.push(className)
    }
    el.className = classes.join(' ')
  }
}

exports.addClass = (el, className) => {
  if (el.classList) {
    el.classList.add(className)
  } else {
    el.className += ' ' + className
  }
}

exports.removeClass = (el, className) => {
  if (el.classList) {
    el.classList.remove(className)
  } else {
    el.className = el.className.replace(new RegExp('(^|\\b)' + className + '(\\b|$)', 'gi'), ' ')
  }
}

exports.hasClass = (el, className) => {
  if (el.classList) {
    return el.classList.contains(className)
  }
  return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className)
}
